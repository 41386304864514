import React, {forwardRef, useImperativeHandle, useRef} from "react";
import {Field} from "formik";
import CreatableSelect from 'react-select/creatable';

import './custom-datalist.scss';

const CustomDatalist = forwardRef(({name, options, placeholder, data, setFilterCountryData, setCountry, setFormError, setFieldValue, includeMultipleGeos, defaultInputValue, from }, ref) =>
{
    const creatableSelectRef = useRef();
    const updateFilterData = (val) => {
        let selectedCountries = [];

        if (from === 'yieldkit') {
            if (includeMultipleGeos) {
                selectedCountries = data.filter(el => el['3. Country code'].includes(val));
            } else {
                selectedCountries = data.filter(el => el['3. Country code'] === val);
            }
        }

        setFilterCountryData(selectedCountries);
        setFieldValue('pagesTo', selectedCountries.length);
        if (options.filter(el => el.value === val).length === 0) {
            setFormError(true);
        } else {
            setFormError(false);
        }
    };
    useImperativeHandle(ref, () => ({
        clearValue() {
            creatableSelectRef.current.clearValue();
        },
        refreshFilterData() {
            if (creatableSelectRef.current.getValue().length > 0) {
                updateFilterData(creatableSelectRef.current.getValue()[0].value);
            }
        }
    }));
    const resetFilterData = () => {
        setFilterCountryData(data);
        setCountry('');
        setFieldValue('pagesTo', data.length);
        setFormError(false);
    };

    return (
        <div className="custom-datalist">
            <Field name={name}>
                {({form}) => (
                    <CreatableSelect
                        ref={creatableSelectRef}
                        options={options}
                        placeholder={placeholder}
                        isClearable
                        defaultValue={{ label: defaultInputValue, value: defaultInputValue }}
                        isDisabled={defaultInputValue ? true : false}
                        onChange={(option, actionMeta) => {
                            if (actionMeta.action === 'select-option' || actionMeta.action === 'create-option') {
                                form.setFieldValue(name, option.value);
                                setCountry(option.value)
                                updateFilterData(option.value);
                            } else if (actionMeta.action === 'clear') {
                                form.setFieldValue('country', '');
                                resetFilterData();
                            }
                        }}
                    />
                )}
            </Field>
        </div>
    );
});

export default CustomDatalist;