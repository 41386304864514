import React, {useState, useEffect, useRef} from "react";
import {useDispatch} from "react-redux";
import {Field, Form, Formik} from "formik";
import CustomDatalist from "../../CustomDatalist";
import {setIsVoluumCSVdifferentColumn} from "../../../redux/dataUploader/dataSlice";
import {getAnOfferCountries} from "../../../helpers/get-an-offers-countries";
import Loader from "../../loaders/Loader";
import CsvDownloadNewFormat from "../../buttons/CsvDownloadNewFormat";
import MessageSendingData from "../MessageSendingData";

const CSVinVoluumFormatPopupMultipleSteps = ({csvData, closePopup, anAccount, userId, selectedCountryFromFilter, selectedCountryOperator, urlSearchParams}) => {
    const dispatch = useDispatch();
    const [initialValues, setInitialValues] = useState({ // eslint-disable-line no-unused-vars
        country: '',
        pagesFrom: 1,
        pagesTo: 0,
        workspaceId: '',
        header: true,
        diffCol: false,
        includeMultipleGeos: true,
        specGeoForMultiple: false,
        blogrefNoref: '',
    });
    const [currentValues, setCurrentValues] = useState({
        country: '',
        pagesFrom: 1,
        pagesTo: 0,
        workspaceId: '',
        header: true,
        diffCol: false,
        includeMultipleGeos: true,
        specGeoForMultiple: false,
        blogrefNoref: '',
    })
    const [isLoading, setIsLoading] = useState(false);
    const [filterCountryData, setFilterCountryData] = useState([]);
    const [countriesList, setCountriesList] = useState([]);
    const [country, setCountry] = useState('');
    const [maxOfferCount, setMaxOfferCount] = useState('');
    const [formError, setFormError] = useState(false);
    const [rangeError, setRangeError] = useState(false); // New state for range error
    const countrySelectRef = useRef();
    const specGeoForMultipleRef = useRef();
    const [step, setStep] = useState(1);
    const [totalOffers, setTotalOffers] = useState(0);
    const [confirmationPopupText, setConfirmationPopupText] = useState(null);
    const [showConfirmationPopup, setShowConfirmationPopup] = useState(false);

    useEffect(() => {
        setCurrentValues((previousState) => {
            return {
                ...previousState,
                country: selectedCountryOperator === "in_json" && selectedCountryFromFilter || country,
                pagesTo: maxOfferCount,
                diffCol: currentValues.diffCol,
            };
        });

        setInitialValues(previousValues => ({
            ...previousValues,
            pagesTo: maxOfferCount,
        }));
    }, [country, selectedCountryFromFilter, maxOfferCount]);

    useEffect(() => {
        const getCountries = async () => {
            const anCountries = await getAnOfferCountries();
            let countriesArr = [];

            for (let country in anCountries) {
                countriesArr.push({
                    value: country,
                    label: anCountries[country]
                })
            }

            if (selectedCountryOperator === "not_json") {
                countriesArr = countriesArr.filter(country => country.value !== urlSearchParams.offer_country.value);
            }

            setCountriesList(countriesArr);
        }
        getCountries();
    }, [urlSearchParams.offer_country]);

    useEffect(() => {
        if (step === 2) {
            if (currentValues.pagesTo - currentValues.pagesFrom > 250) {
                setRangeError(true);
            } else {
                setRangeError(false);
            }
        }
    }, [step, currentValues.pagesFrom, currentValues.pagesTo]);

    const validateCountry = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, country: value}
        });
    }

    const validatePagesFrom = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return { ...previousState, pagesFrom: value };
        });

        let error;
        if (value < 1 || value > maxOfferCount) {
            error = 'Please choose a valid start row';
            setFormError(true);
        } else if (value > 250) {
            error = 'The starting value should not be greater than 250';
            setFormError(true);
        } else {
            setFormError(false);
        }
        return error;
    }

    const validatePagesTo = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return { ...previousState, pagesTo: value };
        });

        let error;
        if (value < 1 || value > maxOfferCount) {
            error = 'Please choose a valid end row';
            setFormError(true);
        } else if (value - currentValues.pagesFrom > 250) {
            error = 'The range should not exceed 250 offers';
            setRangeError(true);
        } else {
            setFormError(false);
            setRangeError(false);
        }
        return error;
    }

    const validateHeader = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, header: value}
        });
    }

    const validateWorkspaceId = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, workspaceId: value}
        });
    }
    
    const validateBlogrefNoref = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, blogrefNoref: value}
        });
        let error;
        const expression = /^https:\/\/(?:(?!www\.))[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b([-a-zA-Z0-9()@:%_\+.~#&//=]*)\?\b([-a-zA-Z0-9()@:%_{}\+.~#&//=]*)\=$/;
        const regex = new RegExp(expression);
        if (value && !value.match(regex)) {
            error = 'Value have to started with "https://", no "www.", include "?", end with "="';
            setFormError(true);
        }
        return error;
    }
    const validateDiffColumn = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, diffCol: value}
        });
        dispatch(setIsVoluumCSVdifferentColumn(value));
    }
    const validateIncludeMultipleGeos = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, includeMultipleGeos: value}
        });
    }

    const validateSpecGeoForMultiple = (value, setCurrentValues) => {
        setCurrentValues(previousState => {
            return {...previousState, specGeoForMultiple: value}
        });
    }

    const handleNextStep = async () => {
        setIsLoading(true); // Set loading state to true before making the request

        const requestBody = {
            country: currentValues.country === "" ? "all" : currentValues.country.split(" "),
            multiple_geos: currentValues.includeMultipleGeos,
            where: JSON.stringify(urlSearchParams)
        };

        try {
            const response = await fetch(`${localStorage.getItem("apiUrl")}api/count-offers-by-country/${anAccount}/${userId}`, {
                method: 'POST',
                headers: {
                    "Accept": "application/json",
                    "Content-Type": "application/json"
                },
                body: JSON.stringify(requestBody)
            });

            if (!response.ok) {
                throw new Error('Error post');
            }

            const data = await response.json();
            setInitialValues((previousState) => {
                return {
                    ...previousState,
                    specGeoForMultiple: currentValues.includeMultipleGeos === true
                };
            });
            setStep(2);
            setMaxOfferCount(data);
            setTotalOffers(data);
            setCurrentValues(previousState => {
                return {...previousState, country: ""};
            });
        } catch (err) {
            console.error(err);
                setIsLoading(false)
                setConfirmationPopupText("Something went wrong")
                setShowConfirmationPopup(true)
            }
         finally {
            setIsLoading(false);
        }
    }

    const handlePrevStep = () => {
        setStep(1)
        setCurrentValues(previousState => {
            return {...previousState, includeMultipleGeos: true};
        });
    }


    if (isLoading) {
        return (
            <Loader/>
        )
    }

    return (
        <div className='add-item-popup-container voluum-popup-container'>
            <div className="add-item-popup-block">
                <div className='form-wrap'>
                    <Formik
                        enableReinitialize={true}
                        initialValues={initialValues}>
                        {({errors, touched, values, setFieldValue}) => (
                            <Form>
                                <div className="title-wrap">
                                    <h1>Before Uploading CSV file</h1>
                                    <div className="close-btn" onClick={closePopup}>
                                        <img src={`${process.env.PUBLIC_URL}/images/close-btn.svg`} alt="close popup"/>
                                    </div>
                                </div>
                                <>
                                    <div className="form-flex-container">
                                        {step === 2 && <div className='prev-page-hint'>
                                            <span>Country: {currentValues.country === "" ? "All" : currentValues.country}</span>
                                            <span>Multiple geos: {currentValues.includeMultipleGeos === true ? "On" : "Off"}</span>
                                            <span>Workspace Id: {currentValues.workspaceId}</span>
                                        </div>}
                                        {step === 1 && <div
                                            className='input-block without-config-item-block input-search-select-block'>
                                            <label htmlFor="country">You can choose the country</label>
                                            <CustomDatalist ref={countrySelectRef} id="country" name="country"
                                                            options={countriesList}
                                                            placeholder='Select or enter a country'
                                                            data={csvData}
                                                            setFilterCountryData={setFilterCountryData}
                                                            setCountry={setCountry}
                                                            includeMultipleGeos={currentValues.includeMultipleGeos}
                                                            validate={value => validateCountry(value, setCurrentValues)}
                                                            setFormError={setFormError}
                                                            setFieldValue={setFieldValue}
                                                            defaultInputValue={selectedCountryOperator === "in_json" && selectedCountryFromFilter}
                                            />
                                        </div>}

                                        {step === 2 && (
                                            <fieldset className='input-block without-config-item-block'>
                                                <legend>It is possible to download only a restricted number of offers. Now you have {maxOfferCount} offers to download. You can choose the range of offers</legend>
                                                <div className="input-range-block">
                                                    <div className="input-range-inner-block">
                                                        <label htmlFor="pagesFrom">From</label>
                                                        <Field
                                                            id="pagesFrom"
                                                            name="pagesFrom"
                                                            validate={value => validatePagesFrom(value, setCurrentValues)}
                                                        />
                                                        {errors.pagesFrom && touched.pagesFrom ? <div className='form-error'>{errors.pagesFrom}</div> : null}
                                                    </div>

                                                    <div className="input-range-inner-block">
                                                        <label htmlFor="pagesTo">To</label>
                                                        <Field
                                                            id="pagesTo"
                                                            name="pagesTo"
                                                            type='number'
                                                            validate={value => validatePagesTo(value, setCurrentValues)}
                                                        />
                                                        {errors.pagesTo ? <div className='form-error'>{errors.pagesTo}</div> : null}
                                                    </div>
                                                </div>
                                                {rangeError && <div className='form-error' style={{marginTop: "20px"}}>The number of offers for download should not exceed 250</div>}
                                            </fieldset>
                                        )}


                                        {step === 1 && <div className="input-block without-config-item-block">
                                            <label htmlFor="workspaceId">Workspace Id (if needed)</label>
                                            <Field
                                                id="workspaceId"
                                                name="workspaceId"
                                                type='text'
                                                validate={value => validateWorkspaceId(value, setCurrentValues)}
                                            />
                                            {errors.workspaceId && touched.workspaceId ?
                                                <div className='form-error'>{errors.workspaceId}</div> : null}
                                        </div>}

                                        {step === 2 && <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                type="checkbox"
                                                className="toggle-button"
                                                id='header'
                                                name="header"
                                                validate={value => validateHeader(value, setCurrentValues)}
                                            />
                                            <label htmlFor='header' className="toggle-label">Include column
                                                names</label>
                                        </div>}

                                        {step === 2 && <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                type="checkbox"
                                                className="toggle-button"
                                                id='diffColumn'
                                                name="diffColumn"
                                                validate={value => validateDiffColumn(value, setCurrentValues)}
                                            />
                                            <label htmlFor='diff-column' className="toggle-label">Include "Domain"
                                                column instead of "Offer Name"</label>
                                        </div>}
                                        {step === 1 && <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                type="checkbox"
                                                className="toggle-button"
                                                id='includeMultipleGeos'
                                                name="includeMultipleGeos"
                                                validate={value => validateIncludeMultipleGeos(value, setCurrentValues)}
                                            />
                                            <label htmlFor='include-multiple-geos' className="toggle-label">Include offers with multiple geos</label>
                                        </div>}
                                        {step === 2 && currentValues.includeMultipleGeos && <div className='input-block config-item-block checkbox-block'>
                                            <Field
                                                innerRef={specGeoForMultipleRef}
                                                type="checkbox"
                                                className="toggle-button"
                                                id='specGeoForMultiple'
                                                name="specGeoForMultiple"
                                                validate={value => validateSpecGeoForMultiple(value, setCurrentValues)}
                                                disabled={currentValues.country === ""}
                                            />
                                            <label htmlFor='spec-geo-for-multiple' className="toggle-label">Specify
                                                offers with multiple geos as global</label>
                                        </div>}

                                        {step === 2 && <div className="input-block without-config-item-block after-checkbox-block">
                                            <label htmlFor="blogrefNoref">Blogref/noref</label>
                                            <Field
                                                id="blogrefNoref"
                                                name="blogrefNoref"
                                                type='text'
                                                validate={value => validateBlogrefNoref(value, setCurrentValues)}
                                            />
                                            {errors.blogrefNoref && touched.blogrefNoref ?
                                                <div className='form-error'>{errors.blogrefNoref}</div> : null}
                                        </div>}
                                    </div>
                                </>
                            </Form>
                        )}
                    </Formik>

                    <div className={"form-buttons"}>
                        {step === 1 && <button className={"blue-btn"} onClick={handleNextStep}>Next</button>}
                        {step === 2 && <button className={"blue-btn"} onClick={handlePrevStep}>Prev</button>}
                        {step === 2 && <div className="form-submit-block">

                            <CsvDownloadNewFormat
                                filename='voluum_format_affiliate_network_offers_list'
                                separator={';'}
                                additionalClass={formError || rangeError ? ' disabled' : ''}
                                formError={formError || rangeError}
                                params={currentValues}
                                anAccount={anAccount}
                                userId={userId}
                                urlSearchParams={urlSearchParams}
                                totalOffers={totalOffers}
                                confirmationPopupText={confirmationPopupText}
                                setConfirmationPopupText={setConfirmationPopupText}
                                showConfirmationPopup={showConfirmationPopup}
                                setShowConfirmationPopup={setShowConfirmationPopup}
                            >
                                Download CSV - Voluum format
                            </CsvDownloadNewFormat>
                        </div>}
                    </div>
                </div>
            </div>
            {showConfirmationPopup && (
                <MessageSendingData
                    messageText={confirmationPopupText}
                    setClosePopup={() => setShowConfirmationPopup(false)}
                />
            )}
        </div>
    )
}

export default CSVinVoluumFormatPopupMultipleSteps;
